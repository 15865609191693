<template>
  <el-form-item :prop="prop" :rules="rules" class="grid-content">
    <el-checkbox v-model="inputVal" @input="handleCheck">
      <slot />
    </el-checkbox>
  </el-form-item>
</template>
<script>
export default {
  name: 'CheckboxForm',
  props: {
    prop: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: [Array, Object],
      default: undefined,
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },

  methods: {
    handleCheck(event) {
      this.$emit('onChange', event)
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .el-checkbox__input .el-checkbox__inner
  width: 24px
  height: 24px
  border-radius: 4px !important
  @media (max-width: 1199px)
    width: 16px !important
    height: 16px !important
    border-radius: 2px !important
    &::after
      height: 8px !important
      left: 4px !important
      top: 0 !important
  &::after
    height: 12px
    left: 9px
    top: 3px

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner
  background-color: $color-blue-primary !important
  border-color: $color-blue-primary !important

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner
  border-color: $color-blue-primary !important

::v-deep .el-checkbox__label
  color: $color-gray-primary !important
  font-weight: 400 !important
  font-size: 12px !important
</style>
